body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#map {
  height: 400px;
}

.marker-pin {
  width: 40px;
  height: 40px;
  border-radius: 50% 50% 50% 0;
  background: #c30b82;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}

/* .marker-pin::after {
  content: '';
  width: 34px;
  height: 34px;
  margin: 3px 0 0 3px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
} */

.flight {
  color: white;
}

.pop {
  border: 1px solid #bfbfbf;
  padding: 10px;
  position: absolute;
  bottom: 20px;
  z-index: 1000;
  left: 20px;
  background-color: #fafaf8e0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #616161;
}

.popup {
  visibility: hidden;
}

.custom-div-icon i {
  position: absolute;
  width: 32px;
  font-size: 22px;
  left: 0;
  right: 0;
  margin: 10px 3px auto;
  text-align: center;
}

.custom-div-icon i.awesome {
  margin: 12px auto;
  font-size: 17px;
}

.select {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  width: 10%;
}

@media only screen and (max-width: 760px), (min-width: 768px) and (max-width: 1024px) {
  /* ... */
  .select {
    width: 150px;
  }
}
